import React, {useState} from 'react';
import {checkEmailFormat, promiseWait} from '../../helpers/Utilities'
import settings from '../../setttings.json';

export default function NewsletterForm({ label, btnText, btnArrowUrl }) {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (event) => {

    setIsSubmitting(true);

    // Check if valid Email
    if(!checkEmailFormat(email)){
      alert(`Please enter valid email`);
      setIsSubmitting(false);
      return;
    }

    const frmBody = {
      email : email.trim(),
    }
   
    const options = {
      crossDomain: true,
      dataType: "json",
      contentType: false,
      method: 'POST',
      body: JSON.stringify(frmBody),
    }

    promiseWait(3000).then(()=>{
      fetch(settings.subscribe_email_url, options)
      .then((response) => response.json())
      .then((data) => {
        console.log("RESPONSE:", data);
        alert("You're now subscribed to our newsletter! Get ready for exclusive updates and offers.");
        setIsSubmitting(false);
      })
      .catch((error) => {
        console.error("RESPONSE:", error);
        alert("We're experiencing a temporary technical issue. Please try submitting your email again later. We apologize for any inconvenience.");
        setIsSubmitting(false);
      });
    });

  };

  const SubmitBtn = () =>{
    return <>
    {
      (isSubmitting)
      ? <button className="cs_btn cs_style_1" id="btnSubmitting">
          <span>Submitting...</span>
        </button>
      :  <button className="cs_btn cs_style_1" onClick={handleSubmit}>
          <span>{btnText}</span>
          <i>
            <img src={btnArrowUrl} alt="Icon" />
            <img src={btnArrowUrl} alt="Icon" />
          </i>
        </button>
    }
      
      
    </>
  }
  return (
    <>
      {label && <p>Your Email</p>}
      <form action="#" className="cs_newsletter_form">
        <input
          type="text"
          className="cs_form_field"
          placeholder="example@email.com"
          onChange={(val)=>setEmail(val.target.value)}
        />
       <SubmitBtn />
      </form>
    </>
  );
}
