import React from 'react';
import BannerSectionStyle5 from '../Section/BannerSection/BannerSectionStyle5';
import ContactForm from '../ContactForm';
import Section from '../Section';
import ContactInfoSection from '../Section/ContactInfoSection';
import { pageTitle } from '../../helpers/PageTitle';
import VideoModal from '../VideoModal';
import FormModal from '../FormModal';

export default function Verify({
    title,
    subTitle,
    bgUrl,
    imgUrl,
    videoBtnText,
    videoUrl,
    funfactList,
    btnText,
    btnUrl,
}) {
  pageTitle('Verify');
  return (
    <>
    <FormModal/>
      {/* <BannerSectionStyle5
        bgUrl="/images/contact/banner_bg.svg"
        imgUrl="/images/contact/banner_img.png"
     
        title="Verification Process"
        subTitle="Kindly check our verification process link below <br/>in our youtube channel"
      /> */}
      <div style={{ position: 'relative', paddingBottom: '56.25%', marginTop:100, height: 0, overflow: 'hidden' }}>
      <iframe
        src="https://www.youtube.com/embed/rFGyD9jiihg?si=gvtgXe7aKUmsDJ5x"
        title="Verification Process"
      
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          marginLeft:20,
          //marginRight:20,
          width: '97.5%',
          height: '100%',
        }}
      ></iframe>
    </div>
  
      {/* <div className="container cs_mt_minus_110">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            
          <VideoModal 
                videoUrl={'https://www.youtube.com/embed/rFGyD9jiihg?si=gvtgXe7aKUmsDJ5x'}
                videoBtnText={'See our verification process video'}
                variant="cs_white_color"
              />
          </div>
        </div>
      </div> */}
      <Section
        topMd={200}
        topLg={150}
        topXl={100}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <ContactInfoSection sectionTitle="Contact Us Here" />
      </Section>
    </>
  );
}
