import React from 'react';
import { useState, useEffect } from 'react';
import {checkEmailFormat, promiseWait} from '../../helpers/Utilities'
import settings from '../../setttings.json';
//import useSession from '../../hooks/SessionProvider';
import { useCookies } from 'react-cookie';

export default function FormModal() {
  // const { sessionState, setInitialized } = useSession();
  const [cookies, setCookie] = useCookies(['subscribed']);
  const [toggle, setToggle] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    console.log(`cookie: ${cookies.subscribed}`);
    if(!cookies.subscribed || (cookies && cookies.subscribed !== 1)){
      promiseWait(1000).then(()=>setToggle(true));
    }
  }, []);
 
  const handelClose = () => {
    setToggle(!toggle);
  }

  const handleSubmit = (event) => {

    setIsSubmitting(true);
    console.log(`name: ${name}, email: ${email}`);

    // Check Name has value
    if(name.trim().length < 3){
      alert(`Please enter your name`);
      setIsSubmitting(false);
      return;
    }
    
    // Check if valid Email
    if(!checkEmailFormat(email)){
      alert(`Please enter valid email`);
      setIsSubmitting(false);
      return;
    }

    const frmBody = {
      email : email.trim(),
      name : name.trim(),
    }
   
    const options = {
      crossDomain: true,
      dataType: "json",
      contentType: false,
      method: 'POST',
      body: JSON.stringify(frmBody),
    }
    // promiseWait(3000).then(()=>{
    //   setCookie('subscribed', 1);
    //   setIsSubmitting(false);
    // });
    promiseWait(1000).then(()=>{
      fetch(settings.subscribe_email_url, options)
      .then((response) => response.json())
      .then((data) => {
        console.log("RESPONSE:", data);
        alert(`Thank you for subscribing! We're excited to have you join us. Expect exclusive updates, special offers, and more!`);
        setCookie('subscribed', 1);
        setIsSubmitting(false);
        setToggle(!toggle);
      })
      .catch((error) => {
        console.error("RESPONSE:", error);
        alert("We're experiencing a temporary technical issue. Please try submitting your message again later. We apologize for any inconvenience.");
        setIsSubmitting(false);
        setToggle(!toggle);
      });
    });

  }

  const SubmitBtn = () =>{
    return <>
    {
      (isSubmitting)
      ? <button className="cs_btn cs_style_1" id="btnSubmitting">
          <span>Submitting...Please wait.</span>
        </button>
      : <button className="cs_btn cs_style_1" onClick={handleSubmit} id="btnSubmit">
          <span>Submit</span>
          <i>
            <img src="/images/icons/arrow_white.svg" alt="Icon" />
            <img src="/images/icons/arrow_white.svg" alt="Icon" />
          </i>
        </button>
    }
      
      
    </>
  }


  return (
    <>
      

      <div className={toggle ? 'cs_form_popup active' : 'cs_form_popup'}>
        <div className="cs_form_popup_overlay" />
        <div className="cs_form_popup_content">
          <div className="cs_form_popup_layer" />
          <div className="cs_form_popup_container">
            <div className="cs_form_popup_align">
              <div className="embed-responsive ">
                <div className="embed-responsive-item">
                  {/* Form */}
                  <div className="cs_contact_form cs_style_1 cs_white_bg cs_radius_30">
                    <div className="row">
                    <div className="col-lg-12">
                        <label className="cs_heading_color" style={{fontSize:20, fontWeight:500}}>Join MyCarity today! Help Us Build a Lifeline of Care During Crisis</label>
                        <div style={{height:10}} />
                        <p>
                        Together, we can build a caring and reliable support network for those who need it most.                         </p>
                        <div style={{height:10}} />
                      </div>
                      <div className="col-lg-12">
                        <label className="cs_input_label cs_heading_color">Full Name</label>
                        <input
                           type="text"
                           className="cs_form_field"
                           placeholder="Your Name"
                           onChange={(val)=>setName(val.target.value)}
                        />
                        <div style={{height:10}} />
                        </div>
                      <div className="col-lg-12">
                        <label className="cs_input_label cs_heading_color">Email</label>
                        <input
                          type="email"
                          className="cs_form_field"
                          placeholder="example@email.com"
                          onChange={(val)=>setEmail(val.target.value)}
                        />
                        <div style={{height:10}} />
                        </div>
                      <div className="col-lg-12">
                        <label className="cs_input_label cs_heading_color">Notice</label>
                        <p>
                        By providing your email address, you consent to receive email notifications, alerts, and occasional marketing communications from MyCarity. You can unsubscribe at any time by clicking the unsubscribe link at the bottom of any email you receive from us.
                        </p>
                        <div style={{height:10}} />
                      </div>
                      <div className="col-lg-12">
                      <div style={{height:10}} />
                      <SubmitBtn />
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
            <div className="cs_form_popup_close" onClick={handelClose} />
          </div>
        </div>
      </div>
    </>
  );
}
