import React, {useState} from 'react';
import {checkEmailFormat, promiseWait} from '../../helpers/Utilities'
import settings from '../../setttings.json';
export default function ContactForm() {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (event) => {

    setIsSubmitting(true);
    console.log(`name: ${name}, email: ${email}`,`subject ${subject}, message: ${message}`);

    // Check Name has value
    if(name.trim().length < 3){
      alert(`Please enter your name`);
      setIsSubmitting(false);
      return;
    }
    
    // Check if valid Email
    if(!checkEmailFormat(email)){
      alert(`Please enter valid email`);
      setIsSubmitting(false);
      return;
    }

    // Check Subject has value
    if(subject.trim().length < 3){
      alert(`Please enter subject`);
      setIsSubmitting(false);
      return;
    }

    // Check Message has value
    if(message.trim().length < 3){
      alert(`Please enter message`);
      setIsSubmitting(false);
      return;
    }
    
    // event.preventDefault();

    // Validate form data (e.g., using regular expressions)

    // If validation passes, send form data to server

    const frmBody = {
      email : email.trim(),
      name : name.trim(),
      subject : subject.trim(),
      message : message.trim() 
    }
   
    const options = {
      crossDomain: true,
      dataType: "json",
      contentType: false,
      method: 'POST',
      body: JSON.stringify(frmBody),
    }

    promiseWait(3000).then(()=>{
      fetch(settings.contact_url, options)
      .then((response) => response.json())
      .then((data) => {
        console.log("RESPONSE:", data);
        alert(`Your message has been submitted successfully. We'll respond to your inquiry promptly. Thank you for contacting us.`);
        setIsSubmitting(false);
      })
      .catch((error) => {
        console.error("RESPONSE:", error);
        alert("We're experiencing a temporary technical issue. Please try submitting your message again later. We apologize for any inconvenience.");
        setIsSubmitting(false);
      });
    });

  };

  const SubmitBtn = () =>{
    return <>
    {
      (isSubmitting)
      ? <button className="cs_btn cs_style_1" id="btnSubmitting">
          <span>Submitting...Please wait.</span>
        </button>
      : <button className="cs_btn cs_style_1" onClick={handleSubmit} id="btnSubmit">
          <span>Submit</span>
          <i>
            <img src="/images/icons/arrow_white.svg" alt="Icon" />
            <img src="/images/icons/arrow_white.svg" alt="Icon" />
          </i>
        </button>
    }
      
      
    </>
  }

  return (
    <div className="cs_contact_form cs_style_1 cs_white_bg cs_radius_30">
      <div className="row">
        <div className="col-lg-6">
          <label className="cs_input_label cs_heading_color">Name</label>
          <input
            type="text"
            className="cs_form_field"
            placeholder="Your Name"
            onChange={(val)=>setName(val.target.value)}
          />
          <div className="cs_height_42 cs_height_xl_25" />
        </div>
        <div className="col-lg-6">
          <label className="cs_input_label cs_heading_color">Email</label>
          <input
            type="email"
            className="cs_form_field"
            placeholder="example@email.com"
            onChange={(val)=>setEmail(val.target.value)}
          />
          <div className="cs_height_42 cs_height_xl_25" />
        </div>
        <div className="col-lg-12">
          <label className="cs_input_label cs_heading_color">Subject</label>
          <input
            type="text"
            className="cs_form_field"
            placeholder="Your subject"
            onChange={(val)=>setSubject(val.target.value)}
          />
          <div className="cs_height_42 cs_height_xl_25" />
        </div>
        <div className="col-lg-12">
          <label className="cs_input_label cs_heading_color">Message</label>
          <textarea
            cols={30}
            rows={10}
            className="cs_form_field"
            placeholder="Write something..."
            defaultValue={''}
            onChange={(val)=>setMessage(val.target.value)}
          />
          <div className="cs_height_42 cs_height_xl_25" />
        </div>
        <div className="col-lg-12">
          <div className="cs_height_18" />
          <SubmitBtn />
        </div>
      </div>
    </div>
  );
}
