import React from 'react';
import BannerSectionStyle3 from '../Section/BannerSection/BannerSectionStyle3';
import BannerSectionStyle4 from '../Section/BannerSection/BannerSectionStyle4';
import Section from '../Section';
import AboutSection from '../Section/AboutSection';
import FeaturesSection from '../Section/FeaturesSection';
import AwardSection from '../Section/AwardSection';
import DepartmentSectionStyle2 from '../Section/DepartmentSection/DepartmentSectionStyle2';
import FeaturesSectionStyle2 from '../Section/FeaturesSection/FeaturesSectionStyle2';
import FunFactSection from '../Section/FunFactSection';
import TeamSection from '../Section/TeamSection';
import GallerySection from '../Section/GallerySection';
import AwardSectionStyle2 from '../Section/AwardSection/AwardSectionStyle2';
import { pageTitle } from '../../helpers/PageTitle';
import FormModal from '../FormModal';

const departmentData = [
  {
    title: 'Diagnostic testing',
    subTitle:
      'Blood tests, imaging studies, and other tests to diagnose health conditions',
    iconUrl: '/images/icons/calendar_white.svg',
    href: '/departments/department-details',
  },
  {
    title: 'Rehabilitation services',
    subTitle:
      'Physical therapy, occupational therapy, and other services to help patients recover from injuries',
    iconUrl: '/images/icons/calendar_white.svg',
    href: '/departments/department-details',
  },
  {
    title: 'Preventive care',
    subTitle:
      'Annual checkups, immunizations, and health screenings care preventive',
    iconUrl: '/images/icons/calendar_white.svg',
    href: '/departments/department-details',
  },
  {
    title: 'Treatment for acute and chronic conditions',
    subTitle:
      'Medication management, disease management, and other treatments to improve health outcomes',
    iconUrl: '/images/icons/calendar_white.svg',
    href: '/departments/department-details',
  },
  {
    title: 'Mental health services',
    subTitle:
      'Counseling, therapy, and other services to help patients manage mental health conditions',
    iconUrl: '/images/icons/calendar_white.svg',
    href: '/departments/department-details',
  },
];

const backgroundcheckData = [
  {
    title: 'SSN Trace',
    subTitle:
      'Is a component of a background check that uses an individual Social Security Number (SSN) to gather information about their identity and past addresses. It helps verify that the SSN is valid and matches the person in question. ',
    iconUrl: 'images/icons/award.svg',
  },
  {
    title: 'National Criminal Search (Standard)',
    subTitle:
      'Is a comprehensive background check that involves searching multiple databases and sources across the country to identify any criminal records associated with an individual.',
    iconUrl: 'images/icons/award.svg',
  },
  {
    title: 'Sex Offender Search ',
    subTitle:
      'A process that identifies whether an individual has been convicted of sex-related crimes and is listed on a sex offender registry. A sex offender registry is a system in various countries designed to allow government authorities to keep track of the offenders activities.',
    iconUrl: 'images/icons/award.svg',
  },
  {
    title: 'Global Watchlist Search ',
    subTitle:
      'Searches various government and regulatory databases that list individuals who are either prohibited from certain industries, such as healthcare and finance, or on a Most Wanted criminal list.',
    iconUrl: 'images/icons/award.svg',
  },
];

const featureListWhyChooseUs = [
  {
    title: 'Experienced Team',
    subTitle:
      'Our team includes over 15 years of combined <br/>experience in staff coordination and management, <br/>with a focus on in-home care. Proven ability to <br/>leverage software development skills to streamline operations, improve client experiences, and <br/>increase efficiency in the delivery of care.',
    iconUrl: 'images/icons/professional.svg',
  },
  {
    title: 'Comprehensive <br />In-Home Care Services',
    subTitle:
      'We believe in treating each patient as an individual, and we take the time to understand your unique health needs and concerns.  We offer a wide range of in-home care services on our app, allowing provider to deliver personalized care in the comfort of your own home.',
    iconUrl: 'images/icons/comprehensive.svg',
  },
  {
    title: 'Patient-centered <br />Approach',
    subTitle:
      'Your in-home care journey is unique, and we are honored to be a part of it. We will take the time to truly understand your needs, concerns, and goals. Together, we will create a in-home care app that puts you in the driver seat and empowers you to live your best life.',
    iconUrl: 'images/icons/patient.svg',
  },
  {
    title: 'Complete In-Home <br />Care Mobile Application',
    subTitle:
      ' Our In-Home Care Services Mobile Application will provide a streamlined platform to centralize caregiving tasks, foster seamless communication, and automatic invoicing system vital for both in-home caregivers and professional providers.',
    iconUrl: 'images/icons/facilities.svg',
  },
];

const featureListOurValues = [
  {
    iconSrc: '/images/home_1/compassion.svg',
    title: 'Compassion',
    subTitle:
      'At MyCarity, we recognize that in-home care can be a sensitive experience. Our platform is designed to provide a supportive and compassionate environment, ensuring you feel at ease and cared for every step of the way.',
  },
  {
    iconSrc: '/images/home_1/excellence.svg',
    title: 'Excellence',
    subTitle:
      'We are committed to providing excellent in-home care and services to our clients. We believe in continuously improving our skills, knowledge, and resources to ensure that we deliver the highest quality support possible.',
  },
  {
    iconSrc: '/images/home_1/integrity.svg',
    title: 'Integrity',
    subTitle: `We believe in providing in-home care with integrity and honesty. We are transparent in our communication and decision-making processes, always putting your interests first to offer the best possible solutions.`,
  },
  {
    iconSrc: '/images/home_1/respect.svg',
    title: 'Respect',
    subTitle:
      'We treat all individuals with respect and dignity, regardless of their background, beliefs, or circumstances. We believe that every person deserves to be treated with compassion and kindness.',
  },
  {
    iconSrc: '/images/home_1/teamwork.svg',
    title: 'Teamwork',
    subTitle:
      'We believe in working collaboratively with our team members and other healthcare professionals to provide comprehensive and effective in-home care to our clients.',
  },
];

// const funFactData = [
//   { number: '20+', title: 'Years of experience' },
//   { number: '95%', title: 'Patient satisfaction rating' },
//   { number: '5000+', title: 'Patients served annually' },
//   { number: '10+', title: 'Healthcare providers on staff' },
//   { number: '22+', title: 'Convenient locations in the area' },
// ];

const teamData = [
  {
    imgUrl: 'images/about/doctor_1.png',
    name: 'Sarah Lee',
    designation: 'Certified Nurse',
    description:
      'With expertise in managing minor injuries, cared for kids around neighborhood for about 5 years now and constantly volunteered for kid related events for a Society.',
    social: [
      // { icon: 'fa6-brands:facebook-f', href: '/about' },
      // { icon: 'fa6-brands:linkedin-in', href: '/about' },
      // { icon: 'fa6-brands:twitter', href: '/about' },
    ],
  },
  {
    imgUrl: 'images/about/doctor_2.png',
    name: 'John Smith',
    designation: 'CPR and First Aid Certified',
    description:
      'Current vocational nurse with 17 years of professional experience through Lackland and Fort Sam. I have experience in the following nursing fields Psych nursing, home health, hospital, and assisted living',
    social: [
      // { icon: 'fa6-brands:facebook-f', href: '/about' },
      // { icon: 'fa6-brands:linkedin-in', href: '/about' },
      // { icon: 'fa6-brands:twitter', href: '/about' },
    ],
  },
  {
    imgUrl: 'images/about/doctor_3.png',
    name: 'Susan Bones',
    designation: 'Experienced Paramedics',
    description:
      'I treasure my years of journey on care giving and continue to be proud of my calling as a caregiver. Caring is my goal and kindness is my culture. With experience in managing complex medical conditions in emergency situations',
    social: [
      // { icon: 'fa6-brands:facebook-f', href: '/about' },
      // { icon: 'fa6-brands:linkedin-in', href: '/about' },
      // { icon: 'fa6-brands:twitter', href: '/about' },
    ],
  },
];

// const galleryData = [
//   { imgUrl: '/images/about/portfolio_2_lg.jpeg' },
//   { imgUrl: '/images/about/portfolio_3_lg.jpeg' },
//   { imgUrl: '/images/about/portfolio_1_lg.jpeg' },
//   { imgUrl: '/images/about/portfolio_4_lg.jpeg' },
//   { imgUrl: '/images/about/portfolio_5_lg.jpeg' },
// ];
// const awardData = [
//   {
//     iconUrl: '/images/icons/award.svg',
//     title: 'Malcolm Baldrige National Quality Award',
//   },
//   { iconUrl: '/images/icons/award.svg', title: 'HIMSS Davies Award' },
//   {
//     iconUrl: '/images/icons/award.svg',
//     title: 'Healthgrades National’s Best Hospital',
//   },
//   {
//     iconUrl: '/images/icons/award.svg',
//     title: 'Joint Commission Gold Seal of Approval',
//   },
// ];

export default function About() {
  pageTitle('About');
    // Trigger this when the About page is displayed
    window.gtag('event', 'page_view', {
      page_title: 'About Page',
      page_path: '/about',
    });
  return (
    <>
      <FormModal />
      <BannerSectionStyle3
        bgUrl="/images/about/banner_bg.svg"
        imgUrl="/images/about/banner_img.png"
        title="Welcome to MyCarity <br />Platform: In-Home Care"
        subTitle="Caregiving made easy with MyCarity! Connect with experienced, verified caregivers near you. Personalized In-Home care, when and where you need it."
      />
      {/* <Section topMd={200} topLg={150} topXl={110}>
        <DepartmentSectionStyle2
          sectionTitle="Provides Our Best Services"
          sectionTitleUp="SERVICES"
          data={departmentData}
        />
      </Section> */}

    {/* Start About Section */}
      <Section>
        <AboutSection
          imgUrl="/images/home_1/about.png"
          spiningImgUrl="/images/home_1/about_mini.svg"
          title="About Us"
          subTitle="MYCARITY"
          featureList={[
            {
              featureListTitle:
                'MyCarity is a team of experienced personnels',
              featureListSubTitle:
                'We believe in a holistic approach to in-home care, focusing on nurturing the entire environment, not just the individual receiving care. Our commitment is to provide top-quality, comprehensive support that enhances every aspect of your home life.',
            },
          ]}
        />
      </Section>
      {/* End About Section */}

      <Section topMd={105} topLg={125} topXl={85} bottomMd={50} bottomLg={110}>
        <FeaturesSectionStyle2
          sectionTitle="Why Choose Us"
          imgUrl="images/about/why_choose_us.jpeg"
          data={featureListWhyChooseUs}
        />
      </Section>

      {/* Start Feature Section */}
      <Section
        topMd={75}
        topLg={100}
        topXl={60}
        bottomMd={105}
        bottomLg={100}
        bottomXl={60}
      >
        <FeaturesSection sectionTitle="Our Values" data={featureListOurValues} />
      </Section>
      {/* End Feature Section */}

      {/* Start Award Section */}
      <Section topMd={50} topLg={90} topXl={50}>
        <AwardSection sectionTitle="Basic Background Check" data={backgroundcheckData} />
      </Section>
      {/* End Award Section */}

      {/* <Section>
        <FunFactSection
          bgUrl="images/about/fun_fact_bg.jpeg"
          data={funFactData}
        />
      </Section> */}
      <Section topMd={80} topLg={145} topXl={105}>
        <TeamSection
          sectionTitle="Featured Providers"
          sectionTitleUp="MEET PROVIDERS"
          data={teamData}
        />
      </Section>
      {/* <Section topMd={170} topLg={120} topXl={80}>
        <GallerySection
          sectionTitle="Our Facilities and <br />Latest Activities"
          sectionTitleUp="HAVE A LOOK AT"
          data={galleryData}
        />
      </Section> */}
      {/* <Section
        topMd={190}
        topLg={145}
        topXl={105}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <AwardSectionStyle2
          sectionTitle="Winning Awards and <br />Recognition"
          sectionTitleUp="AWARDS"
          sectionSubTitle="We are committed to <br />excellence in in-home care."
          data={awardData}
        />
      </Section> */}
      <Section className="cs_footer_margin_0">
        <BannerSectionStyle4
          bgUrl="images/about/banner_bg_2.jpeg"
          title="Don’t Let Your Loved  <br />Ones Take a Backseat!"
          subTitle="Schedule an appointment with one of our <br />experienced care provider today!"
          center
        />
      </Section>
    </>
  );
}
